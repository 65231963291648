import {Injectable} from '@angular/core';
import {TenantService} from '../../services/tenant.service';
import {CommunityPost} from '../../models/community/community-post.model';
import {CommunityPostComment} from "../../models/community/community-post-comment.model";
import {User} from '../../models/user.model';
import {
  Firestore,
  QueryConstraint,
  Timestamp,
  collection,
  collectionData,
  deleteDoc,
  doc,
  docData,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
  writeBatch,
  getDocs
} from '@angular/fire/firestore';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunityPostsService {

  constructor(
    private db: Firestore,
    private tenant: TenantService) {

  }


  async getCommunityPost(postId: string): Promise<CommunityPost> {
    const path = `schools/${this.tenant.id}/communityPosts/${postId}`;
    const result = await docData(doc(this.db, path), {idField: "id"}).pipe(first()).toPromise() as CommunityPost;

    if (!result) {
      return null;
    }

    return {
      id: result.id,
      ...result
    } as CommunityPost;
  }

  async loadCommunityPosts( pageSize: number, lastPostDoc?: any, roomId?: string): Promise<CommunityPost[]> {
    const path = `schools/${this.tenant.id}/communityPosts`;
    const constraints: QueryConstraint[] = [
      orderBy('createdAt', 'desc')
    ]

    if (roomId) {

      constraints.push(where('roomId', '==', roomId));
    }

    if (lastPostDoc) {

      constraints.push(startAfter(lastPostDoc));

    }

    constraints.push(limit(pageSize))

    let baseQuery = query(collection(this.db, path), ...constraints)
    const result = await collectionData(baseQuery, {idField: "id"}).pipe(first()).toPromise()

    return result.map((communityPost: CommunityPost) => {
      return {
        ...communityPost
      }
    })
  }

  async loadCommunityPostsForUser(userId: string, pageSize: number, lastPostDoc?: any): Promise<CommunityPost[]> {
    const path = `schools/${this.tenant.id}/communityPosts`;
    const constraints: QueryConstraint[] = [
      orderBy('createdAt', 'desc'),
      where('userId', '==', userId)
    ]

    if (lastPostDoc) {

      constraints.push(startAfter(lastPostDoc));

    }

    constraints.push(limit(pageSize))

    let baseQuery = query(collection(this.db, path), ...constraints)
    const result = await collectionData(baseQuery, {idField: "id"}).pipe(first()).toPromise()

    return result.map((communityPost: any) => {
      return {
        ...communityPost
      }
    })
  }

  async saveCommunityPostComment(communityPostId: string, comment: any) {
    const path = `schools/${this.tenant.id}/communityPosts/${communityPostId}/postComments`;
    const docRef = doc(this.db, path + "/" + comment.id)

    return await setDoc(docRef, comment)
  }

  async loadCommunityPostComments(postId: string, pageSize?: number, lastCommentDoc?: any): Promise<CommunityPostComment[]> {
    const path = `schools/${this.tenant.id}/communityPosts/${postId}/postComments`;
    const constraints: QueryConstraint[] = [
      orderBy('createdAt', 'desc')
    ];

    if (lastCommentDoc) {

      constraints.push(startAfter(lastCommentDoc))

    }

    constraints.push(limit(pageSize))

    const baseQuery = query(collection(this.db, path), ...constraints)
    const result = await collectionData(baseQuery, {idField: "id"}).pipe(first()).toPromise()

    return result.map((snap) => {

        return {
          ...snap
        } as CommunityPostComment;
    });
  }

  async saveCommentReply(postId: string, reply: any) {
    const path = `schools/${this.tenant.id}/communityPosts/${postId}/postComments/${reply.id}`;

    return await updateDoc(doc(this.db, path), {...reply});
  }

  async updatePost(post: any) {
    const data = {
      ...post,
      lastUpdatedAt: Timestamp.now(),
    }

    return await updateDoc(doc(this.db, `schools/${this.tenant.id}/communityPosts/${post.id}`), {...data});
  }

  async deleteComment(postId: string, commentId: string, ) {
    const path = `schools/${this.tenant.id}/communityPosts/${postId}/postComments/${commentId}`;

    return await deleteDoc(doc(this.db, path));
  }

  likeCommunityPost(post: CommunityPost) {
    const path = `schools/${this.tenant.id}/communityPosts/${post.id}`;

    return setDoc(doc(this.db, path), {...post}, {merge: true});
  }

  async updatePreviewComments(post: CommunityPost) {
    const path = `schools/${this.tenant.id}/communityPosts/${post.id}`;
    const data = {
      ...post,
      lastUpdatedAt: Timestamp.now(),
    }

    return await updateDoc(doc(this.db, path), {...data});
  }

  async createCommunityPost(communityPost: CommunityPost) {
    const path = `schools/${this.tenant.id}/communityPosts`;
    const docRef = doc(this.db, path + "/" + communityPost.id);

    await setDoc(docRef, communityPost);
  }

  async updateCommunityPost(communityPostId: string, communityPost: any) {
    const path = `schools/${this.tenant.id}/communityPosts/${communityPostId}`;
    const docRef = doc(this.db, path);

    await updateDoc(docRef, {...communityPost});
  }

  async deleteCommunityPost(communityPostId: string) {
    const path = `schools/${this.tenant.id}/communityPosts/${communityPostId}`;
    const docRef = doc(this.db, path);

    await deleteDoc(docRef);
    await this.deleteCommunityPostComment(communityPostId);
  }

  async deleteCommunityPostComment(communityPostId: string) {
    const path = `schools/${this.tenant.id}/communityPosts/${communityPostId}/postComments`;
    const batch = writeBatch(this.db)
    const comments = await getDocs(collection(this.db, path))

    comments.forEach((comment: any) => {
      comment?.ref ? batch.delete(comment?.ref) : null;
    });

    await batch.commit();

  }

  async updateCurrentUser(userId: string, changes: any) {
    const path = `schools/${this.tenant.id}/users/${userId}`;

    return await setDoc(doc(this.db, path), {...changes}, {merge: true});
  }

  async getUser(userId: string): Promise<User> {
    const path = `schools/${this.tenant.id}/users/${userId}`;
    const result = await docData(doc(this.db, path), {idField: "id"}).pipe(first()).toPromise() as User;

    if (!result) {
      return null;
    }

    return {
      id: result.id,
      ...result
    }
  }
}
